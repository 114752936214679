import { get, getQuerySearchParams } from "@/lib/axios";

export async function fetchUsers(
  fetchProps = { currentPage: 1, searchUid: "", limit: 10, searchParam: "" }
) {
  try {
    const {
      currentPage = 1,
      searchUid = "",
      limit = 10,
      searchParam = "",
    } = fetchProps;

    const url = `/users?page=${currentPage}&limit=${limit}&${getQuerySearchParams(
      {
        searchUid,
      }
    )}&${searchParam}`;

    const response = await get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}
