"use client";

import { useCallback, useEffect, useState } from "react";
import { fetchUsers } from "../actions";
import { useInView } from "react-intersection-observer";

export default function useUserPaginate(queryProps) {
  const { ref, inView } = useInView();

  const [error, setError] = useState("");

  const [data, setData] = useState({
    currentPage: 1,
    data: [],
    ...queryProps?.defaultData,
  });

  const [loading, setLoading] = useState(true);

  const [fetchProps, setFetchProps] = useState({
    withInfiniteScroll: false,
    limit: 50,
    currentPage: data.currentPage,
    ...queryProps,
  });

  const handlePageChange = useCallback(
    (direction) => {
      if (direction === "next" && fetchProps.currentPage < data.totalPages) {
        setFetchProps((prev) => ({
          ...prev,
          reload: false,
          currentPage: prev.currentPage + 1,
        }));
      } else if (direction === "prev" && fetchProps.currentPage > 1) {
        setFetchProps((prev) => ({
          ...prev,
          reload: false,
          currentPage: prev.currentPage - 1,
        }));
      }
    },
    [fetchProps.currentPage, data.totalPages]
  );

  const initialFetch =
    !fetchProps.reload &&
    (data.totalPages === undefined || (loading && data.data.length === 0));

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError("");

        const res = await fetchUsers(fetchProps);

        const removeDup = (arr = []) => {
          const list = [];
          const map = {};

          for (const item of arr) {
            if (!map[item.id]) {
              map[item.id] = true;
              list.push(item);
            }
          }
          return list;
        };

        setData(
          !fetchProps.reload && fetchProps.withInfiniteScroll
            ? (prev) => ({
                ...prev,
                ...res,
                data: removeDup(prev.data.concat(res.data)),
              })
            : res
        );
      } catch (err) {
        setError("Something went wrong while fetching data.");
      } finally {
        setLoading(false);
      }
    })();
  }, [fetchProps]);

  useEffect(() => {
    if (fetchProps.withInfiniteScroll && !initialFetch && !loading && inView)
      handlePageChange("next");
  }, [
    fetchProps.withInfiniteScroll,
    initialFetch,
    inView,
    loading,
    handlePageChange,
  ]);

  return {
    infiniteRef: ref,
    inView,
    loading,
    originalData: data,
    fetchProps,
    initialFetch,
    error,
    data: data.data,
    reloading:
      initialFetch ||
      (fetchProps.withInfiniteScroll
        ? loading === true && fetchProps.reload
        : loading),
    emptyData:
      !fetchProps.reload &&
      !loading &&
      data.currentPage === 1 &&
      !data.data.length,
    hasMoreData: loading || data.currentPage < data.totalPages,
    withInfiniteScroll: !!fetchProps.withInfiniteScroll,
    handlePageChange,
    setFetchProps,
    setReload: useCallback(
      (stateProp) =>
        setFetchProps((rest) => ({
          ...(typeof stateProp === "function"
            ? stateProp(rest)
            : { ...rest, ...stateProp }),
          currentPage: 1,
          reload: true,
        })),
      []
    ),
    refetch: useCallback((props) => {
      setFetchProps((rest) => ({ ...rest, ...props }));
    }, []),
  };
}
