"use server";

import { getServerSession } from "next-auth";
import { authOptions } from "../app/api/auth/[...nextauth]/options";
import getHeaders, { getCookiesByName } from "@/utils/headerCookies";
import { instance } from "./axios-default";

export const updateUserProfileCover = async (
  userId: string,
  data: FormData,
  searchParam: string = ""
) => {
  try {
    // @ts-ignore
    const session = await getServerSession(authOptions);
    const cookie = await getCookiesByName("access_token");
    const cookies = getHeaders();

    if (!session) {
      throw { error: "You need to sign in" };
    }

    const response = await instance.put(
      `/users/update-profile-cover/${userId}?${searchParam}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          ...cookies,
        },
      }
    );

    return response.data;
  } catch (err: any) {
    return {
      message:
        err.response?.data?.message ||
        "Something went wrong while updating profile",
      success: false,
    };
  }
};

// "use server";

// import { getServerSession } from "next-auth";
// import { authOptions } from "../app/api/auth/[...nextauth]/options";
// import getHeaders, { getCookiesByName } from "@/utils/headerCookies";
// import { instance } from "./axios-default";

// export const updateUserProfileCover = async (
//   userId: string,
//   data: FormData
// ) => {
//   try {
//     // @ts-ignore
//     const session = await getServerSession(authOptions);
//     const cookie = await getCookiesByName("access_token");
//     const cookies = getHeaders();

//     if (!session || !cookie) {
//       return { error: "You need to sign in" };
//     }

//     const response = await instance.put(
//       `/users/update-profile-cover/${userId}`,
//       data,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           ...cookies,
//         },
//       }
//     );
//     return response.data;
//   } catch (err: any) {
//     if (err instanceof Error) {
//       return {
//         error: err.message,
//         // @ts-ignore
//         status: err.status,
//       };
//     }
//     return { error: "Error updating user profile!" };
//   }
// };
