export const truncateText = (text = "", length) => {
  return text.length > length ? text.slice(0, length - 3) + "..." : text;
};

export const getUsername = (
  user = {},
  config = {
    fullname: false,
    fb: undefined,
    length,
  }
) => {
  const { fullname = false, fb = undefined, length } = config;

  // return user.email || user.lastname;

  const text = fullname
    ? `${user.firstname || ""}${user.lastname ? " " + user.lastname : ""}` ||
      (fb === undefined ? "" : fb === "mail" ? user.email?.split("@")[0] : fb)
    : user.firstname ||
      user.username ||
      (fb === undefined ? user.email?.split("@")[0] : fb);

  return length ? truncateText(text, length) : text;
};

export const duplicateToView = (arr = [], ratio = 1) => {
  const dup = arr.slice();

  const length = Math.floor(window.innerWidth / ratio);

  if (dup.length < length) {
    let count = 0;
    for (let i = 0; i < length - dup.length; i++) {
      let c = !!dup[count];

      if (!c) count = 0;

      dup.push(dup[count]);

      count++;
    }
  }

  return dup;
};

export function isTouchScreenDevice() {
  // Check for touch events support
  const hasTouchEvents =
    "ontouchstart" in window || navigator.maxTouchPoints > 0;

  // Check for a PointerEvent with touch support
  const hasPointerEvents = window.PointerEvent && navigator.maxTouchPoints > 0;

  return hasTouchEvents || hasPointerEvents;
}
