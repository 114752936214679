import Image from "next/image";
import leftArrow from "../../public/svgs/left-arrow.svg";
import rightArrow from "../../public/svgs/right-arrow.svg";

export default function Paginate({ currentPage, handlePageChange }) {
  return (
    <center className="flex items-center justify-center mx-auto my-[20px] gap-2">
      <button
        type="button"
        onClick={() => handlePageChange("prev")}
        className="cursor-pointer"
      >
        <Image src={leftArrow} height={32} width={32} alt="arrow" />
      </button>

      <p>{currentPage}</p>

      <button
        type="button"
        onClick={() => handlePageChange("next")}
        className="ml-[6px] cursor-pointer"
      >
        <Image src={rightArrow} height={13} width={13} alt="arrow" />
      </button>
    </center>
  );
}
