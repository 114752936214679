"use client";

import React from "react";
import Image from "next/image";
import { useRouter } from "next/navigation";

const StoryAvatar = ({ name, image, id }) => {
  const router = useRouter();

  const imgClassName =
    " rounded-[100%] border-[3px] border-[#FFBF00] w-[50px] min-w-[50px] h-[50px] min-h-[50px]";

  return (
    <div
      onClick={() => router.push(`/user/${id}`)}
      className="
      flex flex-col cursor-pointer items-center gap-1 min-w-[50px] w-[50px] 
      text-ellipsis
      "
    >
      {image ? (
        <Image
          src={image}
          height={50}
          width={50}
          alt={name}
          className={imgClassName}
        />
      ) : (
        <div className={`${imgClassName} bg-gray-50`} />
      )}
      <p className="text-[12px] font-cormorant text-ellipsis">{name}</p>
    </div>
  );
};

export default StoryAvatar;
