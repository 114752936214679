export default function mediaCount(data = []) {
  const isVideo = (item) => {
    if (!item?.mimetype) return false;
    return item.mimetype.toLowerCase().indexOf("video") > -1;
  };

  const isImage = (item) => {
    if (!item?.mimetype) return false;
    return item.mimetype.toLowerCase().indexOf("image") > -1;
  };

  const isValidMedia = (item) => {
    return isVideo(item) || isImage(item);
  };

  const imageCount = data.filter((item) => isImage(item)).length;
  const videoCount = data.filter((item) => isVideo(item)).length;

  return {
    imageCount,
    videoCount,
    isVideo,
    isValidMedia,
    mainSrc: (data[0] || data[1] || data[2] || data[3])?.url || "",
  };
}
