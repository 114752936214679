import { get } from "@/lib/axios";

export async function fetchUser(id) {
  try {
    const response = await get(`/users/${id}`);
    const fetchedData = response.data;

    return fetchedData;
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
}
