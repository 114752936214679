"us client";

import { getUsername } from "@/utils";
import mediaCount from "@/utils/media-count";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";

const UserCard = ({ user }) => {
  const { imageCount, videoCount, mainSrc } = mediaCount(user.profileCover);

  const router = useRouter();

  const handleUserClick = () => {
    router.push(`/user/${user.id}`);
  };

  const imgClassName = "w-full h-[206px] object-cover rounded-t-lg";

  const name = getUsername(user);

  return (
    <div
      className="relative bg-white rounded-lg shadow-md w-full h-[384px] cursor-pointer"
      onClick={handleUserClick}
    >
      <Image
        width={16}
        height={16}
        src={"/images/verify.png"}
        alt={name}
        className="
w-[16px] h-[16px] absolute mt-[10px] ml-[10px]
"
      />
      {mainSrc ? (
        <Image
          src={mainSrc}
          alt={name}
          width={310}
          height={206}
          className={imgClassName}
        />
      ) : (
        <div className={`${imgClassName} bg-gray-200`} />
      )}
      <ul
        className="
absolute flex mt-[-40px] ml-[10px] 
items-center gap-1"
      >
        <li className="flex items-center gap-1">
          <Image
            width={25}
            height={25}
            src={"/images/capture.png"}
            alt={name}
            className="w-[25px] h-[25px]"
          />
          <span className="text-white text-[16px]">{imageCount}</span>
        </li>
        <li className="flex items-center gap-1">
          <Image
            width={25}
            height={25}
            src={"/images/play.png"}
            alt={name}
            className="w-[25px] h-[25px]"
          />
          <span className="text-white text-[16px]">{videoCount}</span>
        </li>
      </ul>
      <div
        className="
flex flex-col gap-2 p-[10px] mt-[10px]"
      >
        <div className="break-all flex items-center gap-0 text-[16px] font-bold">
          <h2>{name}</h2>
          {user.bio.age ? <span>, {user.bio.age}</span> : null}
        </div>
        <p className="text-[12px] break-all">
          {user.bio.description || "No description available"}
        </p>
      </div>
    </div>
  );
};

export default UserCard;
