"use client";

import React, { useRef, useState } from "react";
import allCountries from "./../../utils/countries";
import { createSearchParam, removeSearchParam } from "@/lib/axios";
import { toast } from "react-toastify";

export const numRange = (num, startNum) =>
  Array.from({ length: num }, (_, i) => ({
    value: i + startNum,
    label: i + startNum,
  }));

const FilterMatches = ({ setReload, renderTitle = true, readOnly }) => {
  const stateRef = useRef({});

  const [userInterest, setUserInterest] = useState({
    interest: "",
    age: "",
    country: "",
  });

  const interestArray = [
    { value: "male-looking for woman", label: "Man looking for woman" },
    { value: "female-looking for man", label: "Woman looking for man" },
    { value: "female-looking for woman", label: "Woman looking for woman" },
    { value: "male-looking for man", label: "Man looking for man" },
  ];

  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setUserInterest({
      ...userInterest,
      [name]: value,
    });
  };

  const handleFilter = () => {
    if (readOnly) return toast("Sorry, currently fetching data!");

    // const fetched = stateRef.current.fetched;

    // if (!fetched && !searchParam) return toast("Please select an option!");

    // stateRef.current.fetched = !fetched;

    setReload((props) => {
      const search = removeSearchParam(
        props.searchParam,
        Object.keys(userInterest),
        "filter"
      );

      return {
        ...props,
        searchParam: createSearchParam(undefined, userInterest)
          ? createSearchParam(search, userInterest, "filter")
          : search,
      };
    });
  };

  return (
    <div className="flex flex-col items-center font-poppins px-4 mt-8">
      {renderTitle ? (
        <h1 className="md:text-[25px] text-[12px] font-medium mb-[20px]">
          Filter your matches
        </h1>
      ) : null}
      <form
        action=""
        className={`
        flex items-center gap-[20px] flex-wrap md:flex-nowrap 
        md:w-full w-[350px]
        ${renderTitle ? "justify-center" : "justify-start"}
      `}
      >
        <div>
          <select
            name="interest"
            value={userInterest.interest}
            onChange={handleInfoChange}
            className="md:w-[235px] w-[113px] md:h-[50px] h-[36px] border-[1px] rounded-[5px] outline-none border-[#000] px-[5px] md:text-[14px] text-[10px] font-medium"
          >
            <option value="" className="text-[#aaa7a7]">
              Select Interest
            </option>
            {interestArray.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <label htmlFor="age" className="flex items-center gap-[5px]">
          Age
          <div>
            <select
              id="age"
              name="age"
              value={userInterest.age}
              onChange={handleInfoChange}
              className="md:w-[113px] w-[61px] h-[36px] md:h-[50px] border-[1px] rounded-[5px] outline-none border-[#000] px-[5px] md:text-[14px] text-[10px] font-medium"
            >
              <option value="" className="text-[#aaa7a7]">
                Select Age
              </option>
              {numRange(60, 18).map((option, index) => (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </label>

        <div className="">
          <select
            id="country"
            name="country"
            value={userInterest.country}
            onChange={handleInfoChange}
            className="md:w-[279px] w-[91px] md:h-[50px] h-[36px] border-[1px] rounded-[5px] outline-none border-[#000] px-[5px] md:text-[14px] text-[10px] font-medium"
          >
            <option value="" className="text-[#aaa7a7]">
              Country of Residence for your match
            </option>
            {allCountries.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <button
          type="button"
          onClick={handleFilter}
          className="md:w-[235px] w-[157px] md:h-[50px] h-[36px] rounded-[5px] bg-[#FFBF00] flex items-center justify-center border-none outline-none md:text-[20px] text-[16px] font-medium text-[#fff] mt-[10px] md:mt-0"
        >
          Filter
        </button>
      </form>
    </div>
  );
};

export default FilterMatches;
