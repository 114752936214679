import axios from "axios";
import { getSession } from "next-auth/react";

export const getQuerySearchParams = (
  options = { searchUid: "", type: "users" }
) => {
  const { searchUid = "", type = "users" } = options;

  return `searchUid=${searchUid}&sortRelevance[bio.plan]=premium&sortRelevance[bio.plan]=standard&sortRelevance[bio.plan]=basic`;
};

export const createSearchParam = (
  searchParam = "",
  objOrArray,
  prefix = ""
) => {
  const url = new URL(`http://localhost:3000?${searchParam}`);

  if (Array.isArray(objOrArray)) {
    for (const key of objOrArray) {
      if (key) url.searchParams.append(`bio`, key);
    }
  } else
    for (const key in objOrArray) {
      const v = objOrArray[key];
      if (v) url.searchParams.set(`${prefix ? prefix + "." : ""}${key}`, v);
    }

  if (url.searchParams.size && prefix)
    url.searchParams.set(`mandatory.${prefix}`, "true");

  return url.searchParams.toString();
};

export const removeSearchParam = (
  searchParam = "",
  keyOrArray,
  prefix = ""
) => {
  const url = new URL(`http://localhost:3000?${searchParam}`);

  if (Array.isArray(keyOrArray)) {
    for (const key of keyOrArray) {
      if (prefix) url.searchParams.delete(`mandatory.${prefix}`);

      url.searchParams.delete(`${prefix ? prefix + "." + key : key}`);
    }
  } else if (keyOrArray) url.searchParams.delete(keyOrArray);

  return url.searchParams.toString();
};

export const baseURL =
  process.env.NEXT_PUBLIC_API_URL ||
  process.env.BASE_URL ||
  "https://sgh-dating-api.glitch.me/api";

const instance = axios.create({
  baseURL,
  withCredentials: true,
});

const refreshToken = async () => {
  try {
    const session = await getSession();

    if (!session) {
      throw new Error("Login session expired. Please login again!");
    }

    await instance.get("/auth/refresh-token?rememberMe=true");

    const newToken = session.access_token;

    if (newToken) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
      return newToken;
    }
  } catch (error) {
    console.error("Token refresh failed:", error);
    throw handleAuthFailure(error);
  }
};

const handleAuthFailure = async (error = {}) => {
  if (error.response?.data)
    error.response.data.message = "Login session expired. Please login again!";
  else error.message = "Login session expired. Please login again!";

  return error;
};

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshToken();
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return instance(originalRequest);
      } catch (refreshError) {
        console.error("Refresh failed:", refreshError);

        return Promise.reject(handleAuthFailure(refreshError));
      }
    }
    return Promise.reject(error);
  }
);

export const initializeAuth = async () => {
  const session = await getSession();
  if (session && session.access_token) {
    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.access_token}`;
  }
};

export const onLoginSuccess = async () => {
  const session = await getSession();
  if (session && session.access_token) {
    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.access_token}`;
  }
};

export const get = async (url, params = {}) => {
  try {
    const response = await instance.get(url, { params });
    return response.data;
  } catch (error) {
    console.error("GET request failed:", error.response || error);
    throw error;
  }
};

export const post = async (url, data = {}, config = {}) => {
  try {
    const response = await instance.post(url, data, config);
    return response.data;
  } catch (error) {
    console.error("POST request failed:", error.response || error);
    throw error;
  }
};

export const put = async (url, data = {}, config = {}) => {
  try {
    const response = await instance.put(url, data, config);
    return response.data;
  } catch (error) {
    console.error("PUT request failed:", error.response || error);
    throw error;
  }
};

export const remove = async (url) => {
  try {
    const response = await instance.delete(url);
    return response.data;
  } catch (error) {
    console.error("DELETE request failed:", error.response || error);
    throw error;
  }
};

export default instance;

export const parseCookieString = (cookieArray, parse) => {
  return cookieArray.map((cookie) => {
    const attrs = cookie.split("; ");

    const result = {};

    for (const attr of attrs) {
      let [name, value] = attr.split("=");

      if (!result.name) {
        result.name = name;
        result.value = value;

        continue;
      }

      name = name.slice(0, 1).toLowerCase() + name.slice(1);

      switch (name) {
        case "expires":
          value = new Date(value);
          break;
        case "maxAge":
          value = Number(value) || 3600;
          break;
        default:
          break;
      }

      result[name] = value || true;
    }

    return parse ? parse(result) || result : result;
  });
};
