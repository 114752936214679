"use client";

import React, { useCallback, useEffect, useState } from "react";
import StoryAvatar from "./StoryAvatar";
import { duplicateToView, getUsername, isTouchScreenDevice } from "@/utils";
import mediaCount from "@/utils/media-count";
import useEmblaCarousel from "embla-carousel-react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import useUserPaginate from "@/src/hooks/useUserPaginate";
import Spinner from "@/utils/Spinner";

const Stories = () => {
  const {
    data: users,
    infiniteRef,
    loading,
    emptyData,
    initialFetch,
  } = useUserPaginate({ limit: 20 });

  const [hideBtn, setHideBtn] = useState(true);

  const [emblaRef, emblaApi] = useEmblaCarousel();

  useEffect(() => {
    if (
      !isTouchScreenDevice() &&
      emblaApi &&
      !initialFetch &&
      emblaApi.canScrollNext()
    )
      setHideBtn(false);
  }, [users, emblaApi, initialFetch]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  if (emptyData) return null;

  return (
    <div className="stories-carousel-container !mb-6">
      <div className="stories-carousel" ref={emblaRef}>
        <div className="flex items-center">
          <div className="stories-container">
            {(initialFetch ? Array.from({ length: 10 }) : users) //duplicateToView(users)
              .map((user = {}, index) => (
                <StoryAvatar
                  key={index}
                  id={user.id}
                  name={getUsername(user, { length: 10 })}
                  image={user.photoUrl || mediaCount(user.profileCover).mainSrc}
                />
              ))}
          </div>
          <div ref={infiniteRef} />
          {loading ? (
            <Spinner
              withOverlay={false}
              containerClassName={`relative ${
                loading ? "-top-[4px]" : "-top-[10px]"
              }`}
            />
          ) : null}
        </div>
      </div>
      <button
        className={`prev-btn ${hideBtn ? "!hidden" : ""}`}
        onClick={scrollPrev}
      >
        <FaChevronLeft />
      </button>
      <button
        className={`next-btn ${hideBtn ? "!hidden" : ""}`}
        onClick={scrollNext}
      >
        <FaChevronRight />
      </button>
    </div>
  );
};

export default Stories;
