"use client";

import useUserPaginate from "@/src/hooks/useUserPaginate";
import Spinner from "@/utils/Spinner";
import UserCard from "./homepage/UserCard";
import Paginate from "./paginate/Paginate";
import FilterMatches from "./homepage/FilterMatches";
import Stories from "./homepage/Stories";

const UserPagination = ({
  queryProps,
  title = "",
  emptyDataMsg = "It seems there is no data for this result",
  noFetchMsg = "It seems you have reached the end!",
  renderAddonEl = null,
  filterTop = false,
}) => {
  const {
    originalData: { currentPage },
    data: users,
    setReload,
    withInfiniteScroll,
    initialFetch,
    emptyData,
    reloading,
    loading,
    hasMoreData,
    infiniteRef,
    handlePageChange,
    refetch,
    error,
  } = useUserPaginate(queryProps);

  const filterEl = (
    <FilterMatches
      readOnly={reloading || loading}
      renderTitle={!withInfiniteScroll}
      setReload={setReload}
    />
  );

  const containViewClassName = `w-full flex items-center justify-center
  gap-4 ${withInfiniteScroll ? "h-full" : "h-[416px]"} ${
    reloading ? "mt-8" : ""
  }`;

  const renderText = (text, className = "", withRetry = false) => {
    return (
      <div className={`${containViewClassName} !flex-col py-4 ${className}`}>
        <h2 className={"text-[16px] font-medium text-center"}>{text}</h2>
        {withRetry ? (
          <button
            type="button"
            className="
              text-black rounded-[3px] bg-[#FFBF00] text-[12px] font-medium 
              h-[40px] w-[90%] mx-auto max-w-[100px]
              "
            onClick={refetch}
          >
            Retry
          </button>
        ) : null}
      </div>
    );
  };

  if (initialFetch) {
    if (error) return renderText(error, "", true);
    else
      return (
        <Spinner
          withOverlay={false}
          containerClassName={containViewClassName}
        />
      );
  }

  if (emptyData) return withInfiniteScroll ? renderText(emptyDataMsg) : null;

  return (
    <div
      style={
        {
          // height: "calc(100% - 150px)",
        }
      }
      className="w-full"
    >
      {withInfiniteScroll ? <Stories users={users} /> : null}

      {title ? (
        <h2 className="text-[25px] font-bold text-center">{title}</h2>
      ) : null}

      <div className="w-full h-full">
        {renderAddonEl ? (
          <div className="mt-4 p-4">{renderAddonEl({ setReload })}</div>
        ) : null}

        {withInfiniteScroll || filterTop ? filterEl : null}

        <div
          className="
          font-poppins flex items-center justify-center flex-col
          w-full h-full mt-8
          "
        >
          {reloading ? (
            <Spinner
              withOverlay={false}
              containerClassName={containViewClassName}
            />
          ) : users.length ? (
            <>
              <div
                className="
    grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3
    s1200:grid-cols-4 gap-4 p-4 w-full
    "
              >
                {users.map((user) => (
                  <UserCard key={user.id} user={user} />
                ))}
              </div>
              {loading ? <Spinner withOverlay={false} /> : null}
              {error ? renderText(error, "", true) : null}
            </>
          ) : (
            renderText(emptyDataMsg, "mt-8")
          )}

          {withInfiniteScroll ? (
            <div ref={infiniteRef} className="w-full" />
          ) : null}
          {!error && withInfiniteScroll && users.length && !hasMoreData
            ? renderText(noFetchMsg, false)
            : null}
          {withInfiniteScroll ? null : (
            <Paginate
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
        {withInfiniteScroll || filterTop ? null : filterEl}
      </div>
    </div>
  );
};

export default UserPagination;
